import styled from 'styled-components'
import Link from 'gatsby-link'
import { NewsCardProps } from '@/components/types'
import { RoundedCornersContainer } from './RoundedCornersContainer'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins } from '@/utils/styleConfig'

export const NewsCard: React.VFC<NewsCardProps> = ({ date, text, link, isNew }) => {
  return link ? (
    <Link to={link}>
      <RoundedCornersContainer>
        <StyledNewsCard>
          <Date>{date}</Date>
          <Text>{text}</Text>
        </StyledNewsCard>
      </RoundedCornersContainer>
    </Link>
  ) : (
    <RoundedCornersContainer>
      <StyledNewsCard>
        <Date>{date}</Date>
        <Text>{text}</Text>
      </StyledNewsCard>
    </RoundedCornersContainer>
  )
}

const StyledNewsCard = styled.div`
  display: flex;
  padding: 2rem 2.4rem;
  background-color: ${colorConfig.bgSubColor};
  font-size: 1.4rem;
  line-height: 1.7;

  ${breakpointDown('sm')} {
    flex-direction: column;
  }
`

const New = styled.span`
  margin-right: 1rem;
  color: ${colorConfig.themeColor};
  text-transform: uppercase;
`

const Date = styled.p`
  flex-shrink: 0;

  ${breakpointUp('md')} {
    padding-right: 2rem;
    flex-basis: 9rem;
  }
`

const Text = styled.p`
  ${breakpointDown('sm')} {
    margin-top: 0.8rem;
  }
`
