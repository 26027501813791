import { VFC } from 'react'
import styled, { css } from 'styled-components'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { LinkButton } from '@/components/LinkButton'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { colorConfig, mixins } from '@/utils/styleConfig'
import { breakpointUp, breakpointDown } from '@/utils/breakpoints'
import { metaData } from '@/feature/staticData'
import { BreakPointHiddenBr } from './Utils'
import { ResponsiveImage } from './ResponsiveImage'

export const TopRecruitCard: VFC = () => (
  <RoundedCornersContainer>
    <Card>
      <ResponsiveImage src="/images/top/recruit_sm.jpg" alt="" />
      <Body>
        <HeadingContainer>
          <HeadingLevel2 note="RECRUIT">採用情報</HeadingLevel2>
        </HeadingContainer>
        <p>
          企業から設計、製作まで、お客様の声が聞ける
          <BreakPointHiddenBr direction="down" breakpoint="md" />
          モノづくりの醍醐味を一緒に感じてみませんか
        </p>
        <LinkButtonContainer>
          <LinkButton
            size="stretch"
            label="詳しく見る"
            link={metaData.recruit.url}
            color="theme"
          ></LinkButton>
        </LinkButtonContainer>
      </Body>
    </Card>
  </RoundedCornersContainer>
)

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorConfig.bgSubColor};
  padding: 2rem;
`

const Body = styled.div`
  display: flex;
  gap: 1.2rem;
  width: 100%;
  align-items: flex-start;
  line-height: 1.7;

  ${breakpointDown('sm')} {
    flex-direction: column;
    padding: 2rem 0rem;
  }

  ${breakpointUp('md')} {
    justify-content: center;
    gap: 6rem;
    padding-top: 2rem;
    padding-bottom: 0;
  }
`

const HeadingContainer = styled.div`
  margin-bottom: 1rem;
  color: ${colorConfig.themeColor};
  flex-shrink: 0;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
`

const Img = styled.img``

const Picture = styled.picture``

const LinkButtonContainer = styled.div`
  ${breakpointDown('sm')} {
    margin-top: 0.8rem;
  }

  ${breakpointUp('md')} {
  }
`
