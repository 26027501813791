// feature
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Seo, LayoutContainer } from '@/utils/globalComponents'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { metaData } from '@/feature/staticData'
import { zIndex } from '@/utils/styleConfig'

// components
import Layout from '@/components/Layout'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { LinkButton } from '@/components/LinkButton'
import { NewsCard } from '@/components/NewsCard'
import { TopCarousel } from '@/components/TopCarousel'
import { TopServiceCard } from '@/components/TopServiceCard'
import { TopRecruitCard } from '@/components/TopRecruitCard'
import { BreakPointHiddenBr, TextThemeColor, VisuallyHidden } from '@/components/Utils'

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      casePages: edges {
        casePage: node {
          id
          slug
          frontmatter {
            cardSize
            date(formatString: "YYYY.M.D")
            newsDate: date(formatString: "YYYY.M")
            summary
            title
            topCarouselPriority
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { casePages } = props.data.allMdx
  const date = new Date()
  const thresholdMonthShowNewCase = new Date(
    date.getFullYear(),
    date.getMonth() - 3,
    date.getDate(),
  )

  const filteredSwiperItems = casePages
    .filter(({ casePage }) => casePage.frontmatter.topCarouselPriority)
    .sort((prevCasePage, nextCasePage) => {
      const prevCasePagePriority = prevCasePage.casePage.frontmatter.topCarouselPriority
      const nextCasePagePriority = nextCasePage.casePage.frontmatter.topCarouselPriority

      if (Number(prevCasePagePriority) > Number(nextCasePagePriority)) {
        return 1
      }
      if (Number(prevCasePagePriority) < Number(nextCasePagePriority)) {
        return -1
      }
    })

  const filteredNewCase = casePages
    .filter(
      ({ casePage }) =>
        // TODO: 出しわけの条件を確認
        // new Date(casePage.frontmatter.date) > thresholdMonthShowNewCase &&
        casePage.frontmatter.summary,
    )
    .slice(0, 3)

  return (
    <Layout marginTop={0} marginBottom={0}>
      <Seo page="top" />

      <VisuallyHidden>
        <h1>株式会社アイアール</h1>
      </VisuallyHidden>

      <TopCarouselContainer>
        <TopCarousel carouselItems={filteredSwiperItems} />
      </TopCarouselContainer>

      <LayoutContainer>
        <CatchCopyContainer>
          <CatchCopy>
            お客様の
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            『あったらいいな』に
            <BreakPointHiddenBr direction="up" breakpoint="md" />
            お応えし
            <br />
            <TextThemeColor>世の中の『楽』</TextThemeColor>
            に貢献する
          </CatchCopy>
        </CatchCopyContainer>
        <TextContainer>
          <p>
            創業132年目を迎える2020年新しく経営理念を一新し
            <BreakPointHiddenBr direction="down" breakpoint="sm" />
            社員一同精進してまいります。
            <br />
            情報システム機器の開発と製造を一貫して行う
            <TextThemeColor>情報機器事業</TextThemeColor>
            、<BreakPointHiddenBr direction="down" breakpoint="sm" />
            科学館、博物館や商業施設など、見て、触って、
            <BreakPointHiddenBr direction="down" breakpoint="sm" />
            感じていただくアイテムを提供する
            <TextThemeColor>ディスプレイ事業</TextThemeColor>。
            <br />
            これらを通じ、Enjoyにする『楽』（楽しさ）を提供しております。
          </p>
          <LinkButtonContainer>
            <LinkButton
              size="stretch"
              color="theme"
              label="詳しく見る"
              link={metaData.company.url}
            />
          </LinkButtonContainer>
        </TextContainer>
        <NewsContainer>
          <SectionHeading>
            <HeadingLevel2 note="NEWS">お知らせ</HeadingLevel2>
          </SectionHeading>
          {filteredNewCase && (
            <NewsList>
              {filteredNewCase.map(({ casePage }) => (
                <li key={casePage.slug}>
                  <NewsCard
                    link={`/case/${casePage.slug}/`}
                    date={casePage.frontmatter.newsDate}
                    text={casePage.frontmatter.summary}
                  />
                </li>
              ))}
              <li>
                <NewsCard date="2020.5" text="HPリニューアルしました。" />
              </li>
              <li>
                <NewsCard date="2020.2" text="会社名を木下製作所からAi-Rに変更いたしました。" />
              </li>
            </NewsList>
          )}
        </NewsContainer>
        <ServiceContainer>
          <ServiceHeading>
            <HeadingLevel2 note="SERVICE" align="end">
              サービス
            </HeadingLevel2>
          </ServiceHeading>
          <ServiceCardContainer>
            <TopServiceCard
              heading="情報機器事業部"
              link={metaData.it.url}
              imgSrc="/images/top/it.jpg"
            >
              独自の技術とノウハウを生かして
              <BreakPointHiddenBr direction="up" breakpoint="md" />
              オフィスの省力化を実現します。
            </TopServiceCard>
            <TopServiceCard
              heading="ディスプレイ事業部"
              link={metaData.display.url}
              imgSrc="/images/top/display.jpg"
            >
              企画からメンテナンスまで
              <BreakPointHiddenBr direction="up" breakpoint="md" />
              一気通貫でお応えします。
            </TopServiceCard>
          </ServiceCardContainer>
        </ServiceContainer>
        <RecruitContainer>
          <TopRecruitCard />
        </RecruitContainer>
      </LayoutContainer>
    </Layout>
  )
}

const CatchCopyContainer = styled.div`
  position: relative;
  text-align: center;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: -12rem;
    left: -5rem;
    width: 15.4rem;
    height: 15.7rem;
    background: top / 100% no-repeat url(/images/top/bg-one.png);

    ${breakpointUp('md')} {
      top: -8rem;
      left: -22rem;
      width: 41rem;
      height: 41.8rem;
    }
  }
`

const CatchCopy = styled.div`
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;

  ${breakpointUp('md')} {
    font-size: 3.2rem;
  }
`

const TextContainer = styled.div`
  ${breakpointDown('sm')} {
    font-size: 1.4rem;
    margin-top: 1.4rem;
    margin-bottom: 2rem;
    line-height: 1.7;
  }

  ${breakpointUp('md')} {
    margin-top: 3.4rem;
    margin-bottom: 2rem;
    line-height: 1.8;
    text-align: center;
  }
`

const TopCarouselContainer = styled.div`
  margin-bottom: 10rem;

  ${breakpointUp('md')} {
    margin-bottom: 6.6rem;
  }
`

const SectionHeading = styled.div`
  ${breakpointDown('sm')} {
    margin-right: 5%;
  }

  ${breakpointUp('md')} {
    flex-basis: 10rem;
    flex-shrink: 0;
  }

  ${breakpointUp('lg')} {
    flex-basis: 22.4rem;
  }
`

const ServiceHeading = styled.div`
  ${breakpointDown('sm')} {
    margin-right: 5%;
    text-align: right;
  }

  ${breakpointUp('md')} {
    flex-basis: 10rem;
    flex-shrink: 0;
  }

  ${breakpointUp('lg')} {
    flex-basis: 22.4rem;
  }
`

const LinkButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;

  ${breakpointUp('md')} {
    margin-top: 4.8rem;
  }
`

const NewsList = styled.ul`
  ${breakpointDown('sm')} {
    margin-top: 2rem;
  }

  ${breakpointUp('md')} {
    flex-grow: 1;
  }

  > li + li {
    margin-top: 1.6rem;
  }
`

const NewsContainer = styled.div`
  position: relative;
  margin-top: 9.2rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: -9rem;
    right: -8.6rem;
    width: 20.9rem;
    height: 21.3rem;
    background: top / 100% no-repeat url(/images/top/bg-two.png);
  }

  ${breakpointUp('md')} {
    display: flex;
    flex-direction: column;
    margin-top: 15.2rem;

    &::before {
      top: -37rem;
      right: -17.6rem;
      width: 41.9rem;
      height: 42.6rem;
    }
  }

  ${breakpointUp('lg')} {
    flex-direction: row;
  }
`

const ServiceContainer = styled.div`
  position: relative;
  margin-top: 10.8rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: -7.6rem;
    left: -3.4rem;
    width: 20.5rem;
    height: 18.8rem;
    background: top / 100% no-repeat url(/images/top/bg-three.png);
  }

  ${breakpointUp('md')} {
    display: flex;
    flex-direction: column;
    margin-top: 15.8rem;

    &::before {
      top: 13rem;
      left: -20.4rem;
      width: 41rem;
      height: 37.6rem;
    }
  }

  ${breakpointUp('lg')} {
    flex-direction: row;
  }
`

const ServiceCardContainer = styled.div`
  display: flex;
  gap: 2.4rem;

  ${breakpointDown('sm')} {
    margin-top: 2rem;
    flex-direction: column;
  }

  ${breakpointUp('md')} {
    justify-content: space-between;
    flex-grow: 1;
    gap: 2rem;
  }
`

const RecruitContainer = styled.div`
  margin-top: 10rem;
  margin-bottom: 16rem;

  ${breakpointUp('md')} {
    margin-top: 15rem;
  }
`

export default IndexPage
