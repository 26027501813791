import { VFC } from 'react'
import styled, { css } from 'styled-components'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { TopServiceCardProps } from '@/components/types'
import { colorConfig, mixins } from '@/utils/styleConfig'
import { breakpointUp, breakpointDown } from '@/utils/breakpoints'
import { LinkButton } from './LinkButton'

export const TopServiceCard: VFC<TopServiceCardProps> = ({ imgSrc, link, heading, children }) => (
  <RoundedCornersContainer>
    <Card>
      <Img src={imgSrc} alt="" width="390" height="248"></Img>
      <Body>
        <Heading>{heading}</Heading>
        {children}
        <LinkButtonContainer>
          <LinkButton size="stretch" label="詳しく見る" link={link} color="theme"></LinkButton>
        </LinkButtonContainer>
      </Body>
    </Card>
  </RoundedCornersContainer>
)

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorConfig.bgSubColor};
  height: 100%;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 4rem 2.4rem;
  line-height: 1.7;
  text-align: center;
`

const Heading = styled.p`
  margin-bottom: 1rem;
  color: ${colorConfig.themeColor};
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
`

const Img = styled.img`
  height: 100%;
`

const LinkButtonContainer = styled.div`
  margin-top: auto;
  padding-top: 2rem;
`
