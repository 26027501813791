import { VFC } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TopCarouselProps } from '@/components/types'
import { colorConfig, mixins } from '@/utils/styleConfig'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { breakpointUp } from '@/utils/breakpoints'
import { metaData } from '@/feature/staticData'

export const TopCarousel: VFC<TopCarouselProps> = ({ carouselItems }) => {
  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        slidesPerView={1.4}
        centeredSlides
        loop
        loopAdditionalSlides={1}
        autoplay={{ delay: 5000 }}
        speed={400}
        navigation
        pagination={{ type: 'bullets', clickable: true }}
      >
        {carouselItems.map(carouselItem => {
          const { frontmatter, slug } = carouselItem.casePage
          return (
            <SwiperSlide key={slug}>
              <Link to={`${metaData.case.url}${slug}/`}>
                <img src={`/images/case/${slug}/1.jpg`} alt="" width="914" height="544" />
                <Title>{frontmatter.title}</Title>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </SwiperContainer>
  )
}

const Title = styled.p`
  display: inline-flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.6rem;
  align-items: center;
  padding: 0.4rem 1.4rem;
  background-color: rgba(230, 0, 0, 0.5);
  color: ${colorConfig.textSubColor};
  min-width: 12rem;
  border-radius: 9999px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-size: 1.2rem;

  ${breakpointUp('md')} {
    margin: 1.6rem 2.4rem;
    padding: 0.8rem 2.4rem;
    font-size: 2rem;
  }
`

const SwiperContainer = styled.div`
  .swiper {
    padding-bottom: 3rem;
    height: 19rem;

    ${breakpointUp('md')} {
      padding-bottom: 4rem;
      height: ${mixins.vw({ size: 590, viewport: 1280 })};
    }
  }

  .swiper-slide {
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background-color: ${colorConfig.textMainColor};
    margin: 0 1rem !important;
    width: 1rem;
    height: 1rem;

    &.swiper-pagination-bullet-active {
      background-color: ${colorConfig.themeColor};
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: calc(50% - 3.2rem);
    margin: auto;
    padding: 1rem;
    width: 3.2rem;
    height: 3.8rem;

    ${breakpointUp('md')} {
      top: calc(50% - 4rem);
      width: 4rem;
      height: 5.4rem;
    }

    &::before {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      background: top / 100% no-repeat url('/images/top/img-arrow.png');
      transform-origin: center;

      ${breakpointUp('md')} {
        height: 3.4rem;
      }
    }

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0.8rem;

    &::before {
      transform: scale(-1, 1);
    }
  }

  .swiper-button-prev {
    left: 0.8rem;
  }

  img {
    height: 100%;
  }
`
